import React from 'react';
import { Link } from 'react-router-dom';

import { checkIsValidUrl, checkIsUrlStartWithProtocol } from 'utils/regex';

const prefixProtocol = url => {
  if (!checkIsUrlStartWithProtocol(url)) {
    return `//${url}`;
  }
  return url;
};

const ExternalLink = ({ to, children, ...props }) => {
  return checkIsValidUrl(to) ? (
    <a href={prefixProtocol(to)}>{children}</a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default ExternalLink;
