import React from 'react';
import { Spin } from 'antd';

import { Mask } from './FullScreenLoading.styles';

const Loading = ({ isLoading }) => {
  return isLoading ? (
    <Mask>
      <Spin size="large" />
    </Mask>
  ) : null;
};

export default Loading;
