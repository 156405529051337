import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';

import { getTodayMoment, getBookingLimitDateMoment, checkIsDate } from 'utils/date';
import { guard } from 'utils/general';

import { StyledDateRangePicker, StyledDateRangePickerMobile } from './DateRangePicker.styles';

const constructDisabledDate = (currentDate, checkInDate, checkOutDate, extraDisabledDate) => {
  const disablePastDate = currentDate < getTodayMoment();
  const disableTwoYearLater = currentDate > getBookingLimitDateMoment();

  const disableCheckInDate = !!checkInDate && checkIsDate('same', currentDate, checkInDate);
  const disableCheckOutDate = !!checkOutDate && checkIsDate('same', currentDate, checkOutDate);

  return disablePastDate || disableTwoYearLater || disableCheckInDate || disableCheckOutDate || extraDisabledDate(currentDate);
};

const DateRangePicker = ({
  value,
  placeholder,

  isDisabled,

  extraDisabledDate,
  onChange,
  ...props
}) => {
  const [selectedCheckInDate, setSelectedCheckInDate] = useState(undefined);
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState(undefined);

  const handleOnCalendarChange = dates => {
    setSelectedCheckInDate(guard(() => dates[0]));
    setSelectedCheckOutDate(guard(() => dates[1]));
  };

  return (
    <>
      <Col span={0} md={24}>
        <StyledDateRangePicker
          value={value}
          placeholder={placeholder}
          disabledDate={calendarDate => constructDisabledDate(calendarDate, selectedCheckInDate, selectedCheckOutDate, extraDisabledDate)}
          disabled={isDisabled}
          onChange={onChange}
          onCalendarChange={handleOnCalendarChange}
          {...props}
        />
      </Col>
      <Col span={24} md={0}>
        <StyledDateRangePickerMobile
          value={value}
          placeholder={placeholder}
          disabledDate={calendarDate => constructDisabledDate(calendarDate, selectedCheckInDate, selectedCheckOutDate, extraDisabledDate)}
          disabled={isDisabled}
          onChange={onChange}
          onCalendarChange={handleOnCalendarChange}
          {...props}
        />
      </Col>
    </>
  );
};

DateRangePicker.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.array,
  isDisabled: PropTypes.bool,
  extraDisabledDate: PropTypes.func,
  onChange: PropTypes.func
};

DateRangePicker.defaultProps = {
  value: [undefined, undefined],
  placeholder: ['Start Date', 'End Date'],
  isDisabled: false,
  extraDisabledDate: () => false,
  onChange: () => {}
};

export default DateRangePicker;
