import { useMemo } from 'react';
import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useFetchConstant = (name, valueKey = 'code', labelKey = 'label') => {
  const getConstant = (key, name) => {
    return api.get(`/constants/${name}`);
  };

  const { isLoading, data, ...returnedParams } = useCustomQuery('constant', [name], getConstant, {
    shouldDefaultEmptyObject: false,
    prefixErrorMessage: `Error while getting ${name}.\n`
  });

  const selection = useMemo(
    () =>
      isLoading || !data
        ? []
        : Object.values(data).map(selection => ({
            ...selection,
            value: selection[valueKey],
            label: selection[labelKey]
          })),
    [isLoading, data, labelKey, valueKey]
  );

  return { isLoading, selection, data, ...returnedParams };
};
