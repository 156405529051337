import React from 'react';
import PropTypes from 'prop-types';

import { StyledAntdRadio, StyledAntdRadioButton, StyledAntdRadioGroup } from './Radio.styles';

const RadioComponent = ({ type, ...props }) => {
  if (type === 'button') {
    return <StyledAntdRadioButton {...props} />;
  } else {
    return <StyledAntdRadio {...props} />;
  }
};

const Radio = ({
  selections,
  type,
  buttonStyle,

  buttonsToDisable,
  isDisabled,

  onChange,
  updateFormValue,
  value,

  ...props
}) => {
  const handleOnChange = e => {
    const value = e.target.value;
    onChange(value);
    updateFormValue(value);
  };

  return (
    <StyledAntdRadioGroup buttonStyle={buttonStyle} disabled={isDisabled} onChange={handleOnChange} value={value} {...props}>
      {selections.map(selection => (
        <RadioComponent type={type} key={selection.value} value={selection.value} disabled={buttonsToDisable.includes(selection.value)}>
          {selection.label || selection.value}
        </RadioComponent>
      ))}
    </StyledAntdRadioGroup>
  );
};

Radio.propTypes = {
  selections: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['default', 'button']),
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  buttonsToDisable: PropTypes.array,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

Radio.defaultProps = {
  buttonStyle: 'solid',
  type: 'default',
  buttonsToDisable: [],
  isDisabled: false,
  onChange: () => {},
  updateFormValue: () => {}
};

export default Radio;
