import styled from '@emotion/styled';
import { Radio as AntdRadio } from 'antd';

const AntdRadioGroup = AntdRadio.Group;
const AntdRadioButton = AntdRadio.Button;

export const StyledAntdRadioGroup = styled(AntdRadioGroup)`
  display: flex;
  width: 100%;
`;

export const StyledAntdRadio = styled(AntdRadio)`
  display: flex;
`;

export const StyledAntdRadioButton = styled(AntdRadioButton)`
  display: flex;
  height: 100%;
`;
