import React from 'react';

import RadioButton from '../Radio';
import FormItem from 'components/FormItem/FormItem';

const FormRadio = ({
  name,
  label,
  selections,
  buttonStyle,
  type,
  defaultValue,
  size,
  requiredErrorMessage,
  extraRules,

  isDisabled,
  buttonsToDisable,

  onChange,
  ...props
}) => {
  return (
    <FormItem name={name} label={label} requiredErrorMessage={requiredErrorMessage} extraRules={extraRules} defaultValue={defaultValue} {...props}>
      <RadioButton
        selections={selections}
        buttonStyle={buttonStyle}
        buttonsToDisable={buttonsToDisable}
        isDisabled={isDisabled}
        onChange={onChange}
        type={type}
        size={size}
      />
    </FormItem>
  );
};

export default FormRadio;
