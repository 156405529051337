import React from 'react';

import { RequiredAsterisk, Root } from './FormLabel.styles';

const FormLabel = ({ className, children, hasRequiredErrorMessage, ...props }) => {
  return (
    <Root className={className} {...props}>
      {hasRequiredErrorMessage ? <RequiredAsterisk>*</RequiredAsterisk> : ''}
      {children}
    </Root>
  );
};

FormLabel.defaultProps = {
  hasRequiredErrorMessage: false,
  className: '',
  align: 'left',
  color: 'black',
  size: 'regular',
  bold: false
};

export default FormLabel;
